import pkg from '../../../package.json';

const LOCATE_URL = 'https://geo.ngtv.io/locate';
const CCPA_NON_USP_LOCATION_STRING = '1---';
const urlObject = {
  featureFlag: {
    DEV: 'https://wmff.warnermediacdn.com/psm_dev_full.json',
    QA: 'https://wmff.warnermediacdn.com/psm_qa_full.json',
    PROD: 'https://wmff.warnermediacdn.com/psm_prod_full.json',
    INTEGRATION: 'https://wmff.warnermediacdn.com/psm_brand_integration_config_full.json',
  },
  privacy: {
    DEV: 'https://dev.privacy.api.wmcdp.io/v1/pvc',
    QA: 'https://test.privacy.api.wmcdp.io/v1/pvc',
    PROD: 'https://privacy.api.wmcdp.io/v1/pvc',
    INTEGRATION: 'https://integration.privacy.api.wmcdp.io/v1/pvc',
  },
  identity: {
    DEV: 'https://dev.identity.api.wmcdp.io/v1/reg',
    QA: 'https://test.identity.api.wmcdp.io/v1/reg',
    PROD: 'https://identity.api.wmcdp.io/v1/reg',
    INTEGRATION: 'https://integration.identity.api.wmcdp.io/v1/reg',
  },
  telemetry: {
    DEV: 'https://dev.telemetry.api.wmcdp.io/v1/tel',
    QA: 'https://test.telemetry.api.wmcdp.io/v1/tel',
    PROD: 'https://telemetry.api.wmcdp.io/v1/tel',
    INTEGRATION: 'https://integration.telemetry.api.wmcdp.io/v1/tel',
  },
  errors: {
    DEV: 'https://dev.logs.psm.wmcdp.io',
    QA: 'https://dev.logs.psm.wmcdp.io',
    PROD: 'https://logs.psm.wmcdp.io',
    INTEGRATION: 'https://dev.logs.psm.wmcdp.io',
  },
};

const ffLocalConfig = {
  featureFlagLibraryVersion: '0.1',
  flags: [
    {
      defaultValue: true,
      id: 'getSetConsentState',
      cohorts: [],
      name: 'FALLBACK_getSetConsentState',
      type: 'boolean',
    },
    {
      defaultValue: true,
      id: 'telemetry',
      cohorts: [],
      name: 'FALLBACK_telemetry',
      type: 'boolean',
    },
    {
      defaultValue: true,
      id: 'WMUKID',
      cohorts: [],
      name: 'FALLBACK_WMUKID',
      type: 'boolean',
    },
  ],
};

const defaultFlagState = [
  {
    flagId: 'getSetConsentState',
    flagName: 'DEFAULT_getSetConsentState',
    enabled: true,
    userId: '',
    userIdType: 'ffUserId',
    warnings: [],
  },
  {
    flagId: 'WMUKID',
    flagName: 'DEFAULT_WMUKID',
    enabled: true,
    userId: '',
    userIdType: 'ffUserId',
    warnings: [],
  },
  {
    flagId: 'telemetry',
    flagName: 'DEFAULT_telemetry',
    enabled: true,
    userId: '',
    userIdType: 'ffUserId',
    warnings: [],
  },
];

function getPsmVersion() {
  return pkg.version;
}

function getUrl(environment, urlType) {
  const uppercaseEnv = environment.toUpperCase();
  let envString;
  if (uppercaseEnv === 'DEV' || uppercaseEnv === 'DEVELOPMENT') {
    envString = 'DEV';
  } else if (
    uppercaseEnv === 'TEST' ||
    uppercaseEnv === 'TESTING' ||
    uppercaseEnv === 'STAGE' ||
    uppercaseEnv === 'STAGING' ||
    uppercaseEnv === 'QA'
  ) {
    envString = 'QA';
  } else if (uppercaseEnv === 'PROD' || uppercaseEnv === 'PRODUCTION') {
    envString = 'PROD';
  } else if (uppercaseEnv === 'INTEGRATION') {
    envString = 'INTEGRATION';
  } else {
    envString = 'PROD';
  }

  const urlString = urlObject[urlType][envString];
  return urlString;
}

export { ffLocalConfig, defaultFlagState, LOCATE_URL, getPsmVersion, getUrl, CCPA_NON_USP_LOCATION_STRING, urlObject };
