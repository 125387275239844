import { setCookie } from '@turnercode/cdp-utils-js';

import { getDomain } from '../helpers';

function setUSPObject(uspString) {
  let uspObject = {
    uspString,
    comScore: 1,
  };
  if (uspString === '1YYN') {
    uspObject = {
      uspString,
      comScore: 0,
    };
  }
  setCookie('uspObject', uspObject, getDomain());
}

function setUSPDataCookie(uspString, spec) {
  const uspData = {
    version: parseInt(spec, 10) || spec,
    uspString,
  };
  setCookie('uspData', uspData, getDomain());
}

function setUSPString(uspString) {
  setCookie('usprivacy', uspString, getDomain());
}

export { setUSPObject, setUSPString, setUSPDataCookie };
