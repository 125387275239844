/* eslint-disable no-bitwise */
import {
  generateUUID,
  getCookies,
  getGeoLocation,
  getOneCookie,
  makeRequest,
  removeCookie,
  setCookie,
} from '@turnercode/cdp-utils-js';

import {
  createBaseEvent,
  debugLogger,
  errorLogger,
  getDomain,
  getGeoMatch,
  isIdentityEnabled,
  sendTelemetryEvent,
} from '../helpers';
import { getUrl } from '../helpers/constants';

function setRetryFlag(options) {
  const cookieName = options;

  const cookie = getOneCookie(cookieName);
  cookie.retry = true;
  setCookie(cookieName, cookie, getDomain());
}

function getKruxId() {
  let kruxId;
  try {
    kruxId = window.Krux.kuid;
    if (!kruxId) {
      kruxId = localStorage.getItem('kxkuid') || '';
    }
  } catch (e) {
    const localKruxId = localStorage.getItem('kxkuid');
    if (typeof localKruxId === 'string') kruxId = localKruxId;
  }
  return kruxId;
}

function updateExternalIds(domain) {
  setCookie('psmRetryExternalIds', 'false', domain);

  let externalIds = getOneCookie('psmExternalIds') || {};
  // What's currently saved as kruxid on the externalIds object
  const existingKruxId = externalIds.kruxid;
  // What is saved in the browser as kuid
  const newKruxId = getKruxId();

  // Don't update if IDs are the same
  if (newKruxId === existingKruxId) {
    return false;
  }

  // If new krux ID isn't a string, the service isn't available
  if (typeof newKruxId !== 'string') {
    externalIds = {};
    // If new KruxID is valid, add to externalIds object
  } else if (newKruxId.length > 0) {
    externalIds.kruxid = newKruxId;
    // If krux service is available, but KruxID isn't valid yet, mark to retry
  } else {
    externalIds.kruxid = 'retry';
    setCookie('psmExternalIds', externalIds, domain);
    setCookie('psmRetryExternalIds', 'true', domain);
    return false;
  }

  setCookie('psmExternalIds', externalIds, domain);
  return true;
}

async function registerIdentity(cookieObject) {
  const psmMetaData = getOneCookie('psmMetaData') || {};
  const baseEventObject = createBaseEvent({ psmMetaData, wmukid: cookieObject.id });
  const retryIds = getOneCookie('psmRetryExternalIds') || false;

  if (baseEventObject.wmukid === 'Unknown') {
    errorLogger('Identity', 'registerIdentity', { message: 'WMUKID is Unknown' }, 'WMUKID');
    return;
  }

  // If useragent contains spider, bot, crawl, or scrape, return
  const userAgent = baseEventObject.userAgent;
  const crawlers =
    '((?:[A-z0-9]+|[A-z-]+ ?)?(?: the )?(?:[Ss][Pp][Ii][Dd][Ee][Rr]|[Ss]crape|[A-Za-z0-9-]*(?:[^C][^Uu])[Bb]ot|[Cc][Rr][Aa][Ww][Ll])[A-z0-9]*)(?:(?:[ /]| v)(d+)(?:.(d+)(?:.(d+))?)?)?';
  if (userAgent.match(crawlers)) {
    return;
  }

  // If externalIds haven't been generated yet, retry WMUKID registration
  if (retryIds) {
    setRetryFlag('WMUKID');
    return 'External IDs not found.';
  }

  // Grabs user's location info
  const locationData = (await getGeoLocation()) || {};

  if (!locationData.states) {
    locationData.states = [{ state: 'Unknown', cities: ['Unknown'], zipcodes: [] }];
  }
  const addressDetails = {
    country: locationData.country_alpha2 || 'Unknown',
    state: locationData.states[0].state || 'Unknown',
    city: locationData.states[0].cities[0] || 'Unknown',
    zips: locationData.states[0].zipcodes || 'Unknown',
  };

  const idEventObject = {
    ...baseEventObject,
    ...addressDetails,
    ip: locationData.ip_address || 'Unknown',
    cookies: getCookies(),
  };

  const env = psmMetaData.environment || 'PROD';
  const identityUrl = getUrl(env, 'identity');

  try {
    const resp = await makeRequest({ url: identityUrl, method: 'POST', payload: idEventObject });
    sendTelemetryEvent('registerIdentity', 'identity-registerWMUKID', addressDetails);
    return resp;
  } catch (error) {
    errorLogger('Identity', 'registerIdentity', error, 'WMUKID');
    setRetryFlag('WMUKID');
    throw error;
  }
}

function createWMUKID() {
  const WMUKID = generateUUID();

  const cookieObject = {
    id: WMUKID,
    version: 0.1,
    timestamp: new Date().toISOString(),
  };
  setCookie('WMUKID', cookieObject, getDomain());
  registerIdentity(cookieObject)
    .then((response) => {
      debugLogger(`identity registered ${JSON.stringify(response) || cookieObject.id}`);
    })
    .catch((e) => {
      errorLogger('Identity', 'createWMUKID', e, 'WMUKID');
    });
  // sendTelemetryEvent('getWMUKID', 'identity-wmukidCreated', {});
  return cookieObject.id;
}

function getWMUKID() {
  const identityEnabled = isIdentityEnabled();
  const geoMatch = getGeoMatch();
  let retryFlag = false;
  if (!identityEnabled || !geoMatch) {
    return 'Unknown';
  }
  let WMUKID = getOneCookie('WMUKID') || null;

  if (!WMUKID) {
    return createWMUKID();
  }

  // sendTelemetryEvent('getWMUKID', 'identity-wmukidFromCache', {});

  retryFlag = WMUKID.retry;

  if (retryFlag) {
    delete WMUKID.retry;
    setCookie('WMUKID', WMUKID, getDomain());
    registerIdentity(WMUKID)
      .then((response) => {
        debugLogger(`identity registered ${JSON.stringify(response) || WMUKID.id}`);
      })
      .catch((e) => {
        setRetryFlag('WMUKID');
        errorLogger('Identity', 'getWMUKID', e, 'WMUKID');
      });
  }

  try {
    WMUKID = WMUKID.id;
  } catch (e) {
    errorLogger('Identity', 'getWMUKID', e, 'WMUKID');
  }
  return WMUKID;
}
function removeWMUKID() {
  removeCookie('WMUKID', getDomain());
}

export { getKruxId, getWMUKID, updateExternalIds, removeWMUKID };
