import { getOneCookie, makeRequest, setCookie } from '@turnercode/cdp-utils-js';

import {
  debugLogger,
  errorLogger,
  getDomain,
  getGeoMatch,
  isPrivacyEnabled,
  createBaseEvent,
  findFrame,
  addFrame,
  setHandler,
  sendTelemetryEvent,
} from '../helpers';

import { getUrl } from '../helpers/constants';
import { getWMUKID } from '../identity';
import { getUSPBoolean, getUSPData, getUSPInt, getUSPObject, getUSPString } from './get-consent';
import { setUSPDataCookie, setUSPObject, setUSPString } from './set-consent';

/**
 * @class PrivacyModule
 */

export class PrivacyModule {
  constructor() {
    this.specCharacter = '1';
    this.opportunity = 'Y';
    this.optOut = 'N';
    this.LSPA = 'N';
    this.uspString = '';
    this.getUSPString = getUSPString;
    this.getUSPObject = getUSPObject;
    this.getUSPInt = getUSPInt;
    this.getUSPBoolean = getUSPBoolean;
    this.getUSPData = getUSPData;
    this.ccpaDoNotShare = this.ccpaDoNotShare.bind(this);
    this.ccpaShareData = this.ccpaShareData.bind(this);
    this.setUSPData = this.setUSPData.bind(this);
    this.__uspapi = this.__uspapi.bind(this);
  }

  async sendPrivacyEvent(optOut) {
    setCookie('retryPrivacy', 'false', getDomain());

    const psmMetaData = getOneCookie('psmMetaData');
    const retryIds = getOneCookie('psmRetryExternalIds') || false;

    let wmukid;

    // If no wmukid param passed, try and get the ID from the cookie itself
    if (!wmukid) {
      try {
        wmukid = getOneCookie('WMUKID').id;
      } catch {
        wmukid = getWMUKID();
      }
    }

    // handles user-consent calling ccpaDoNotShare/ccpaShareData before page fully loads
    if (!wmukid || wmukid === undefined) {
      setCookie('retryPrivacy', 'true', getDomain());
      return errorLogger('pvc', 'sendPrivacyEvent', 'WMUKID not found');
    }

    if (retryIds) {
      setCookie('retryPrivacy', 'true', getDomain());
      return 'Privacy not registered: External IDs not generated yet.';
    }

    const privacyObject = createBaseEvent({ psmMetaData, wmukid });

    const env = psmMetaData.environment || 'PROD';
    const privacyUrl = getUrl(env, 'privacy');

    privacyObject.optOut = optOut;

    try {
      const resp = await makeRequest({ url: privacyUrl, method: 'POST', payload: privacyObject });
      sendTelemetryEvent('sendPrivacyEvent', 'privacy-registerPrivacyEvent', { optOut });
      return resp;
    } catch (error) {
      setCookie('retryPrivacy', 'true', getDomain());
      errorLogger('pvc', 'sendPrivacyEvent', error);
      return error;
    }
  }

  __uspapi(command, version, callback) {
    // sendTelemetryEvent('__uspapi', 'privacy-__uspapi', {});

    if (command === 'getUSPData' && (version === 1 || version === '1')) {
      callback({ version, uspString: getUSPString() }, true);
    } else if (command === 'ccpaDoNotShare') {
      callback({ version, uspString: this.ccpaDoNotShare() }, true);
    } else if (command === 'ccpaShareData') {
      callback({ version, uspString: this.ccpaShareData() }, true);
    } else if (command === 'ping') {
      callback({ version: 1, uspapiLoaded: true }, true);
    } else {
      callback(null, false);
    }
  }

  /**
   * This creates the USPAPI stub function used for IAB.
   */
  createUspIabHandlers() {
    const uspMsgHandler = function _uspMsgHandler(event) {
      try {
        const msgIsString = typeof event.data === 'string';
        let i;
        let json;

        try {
          json = msgIsString ? JSON.parse(event.data) : event.data;
        } catch (e) {
          json = {};
        }
        if (typeof json === 'object' && json !== null && json.__uspapiCall) {
          // sendTelemetryEvent('_uspMsgHandler', 'privacy-uspMessageHandler', {});
          i = json.__uspapiCall;
          window.__uspapi(
            i.command,
            i.version,
            function (retValue, success) {
              const returnMsg = {};

              returnMsg.__uspapiReturn = {
                returnValue: retValue,
                success,
                callId: i.callId,
              };
              event.source.postMessage(msgIsString ? JSON.stringify(returnMsg) : returnMsg, '*');
            },
            i.parameter
          );
        }
      } catch (e) {
        errorLogger('Privacy', '_uspMsgHandler', e);
      }
    };

    const usingUspFrame = findFrame('__uspapiLocator');

    /* CCPA IAB handler must always be defined, even in GDPR regions... */
    if (usingUspFrame === null) {
      /* But only for the top frame */
      addFrame('__uspapiLocator');
      window.__uspapi.msgHandler = uspMsgHandler;
      setHandler(window.__uspapi.msgHandler);
    }
  }

  /**
   * @method PrivacyModule#setUSPData
   * @param {Boolean} optOutBoolean - Pass true if you want to opt out of sharing data and false if you want to opt in to sharing data
   */
  setUSPData(optOutBoolean) {
    sendTelemetryEvent('setUSPData', 'privacy-setUSPData', {});

    const privacyEnabled = isPrivacyEnabled();
    if (!privacyEnabled) {
      debugLogger('privacy is off');
      return;
    }
    if (optOutBoolean) {
      return this.ccpaDoNotShare();
    }
    return this.ccpaShareData();
  }

  ccpaDoNotShare() {
    sendTelemetryEvent('ccpaDoNotShare', 'privacy-ccpaDoNotShare', {});

    const geoMatch = getGeoMatch();
    const privacyEnabled = isPrivacyEnabled();
    const existingUSPString = getUSPString();
    if (!privacyEnabled || !geoMatch) {
      return;
    }
    if (this.optOut === '-') {
      return;
    }
    if (existingUSPString === '1---' || existingUSPString === '1YYN') {
      return;
    }
    this.sendPrivacyEvent(true);
    this.optOut = 'Y';
    this.generateUSPString();
    setUSPString(this.uspString);
    setUSPObject(this.uspString);
    setUSPDataCookie(this.uspString, this.specCharacter);
    return this.uspString;
  }

  ccpaShareData() {
    sendTelemetryEvent('ccpaShareData', 'privacy-ccpaShareData', {});

    const geoMatch = getGeoMatch();
    const privacyEnabled = isPrivacyEnabled();
    const existingUSPString = getUSPString();
    if (!privacyEnabled || !geoMatch) {
      return;
    }
    if (this.optOut === '-') {
      return;
    }
    if (existingUSPString === '1---' || existingUSPString === '1YNN' || !existingUSPString) {
      return;
    }
    this.sendPrivacyEvent(false);
    this.optOut = 'N';
    this.generateUSPString();
    setUSPString(this.uspString);
    setUSPObject(this.uspString);
    setUSPDataCookie(this.uspString, this.specCharacter);
    return this.uspString;
  }

  generateUSPString() {
    const uspString = `${this.specCharacter}${this.opportunity}${this.optOut}${this.LSPA}`;
    this.uspString = uspString;
    return this.uspString;
  }

  initPrivacy() {
    this.createUspIabHandlers();
    const geoMatch = getGeoMatch();
    const existingUSPString = getUSPString();
    const uspBoolean = getUSPBoolean();
    const retryPrivacy = getOneCookie('retryPrivacy') || false;

    if (existingUSPString) {
      debugLogger('Privacy cookie already set');
      if (retryPrivacy) {
        this.sendPrivacyEvent(uspBoolean);
      }
      return existingUSPString;
    } else {
      if (!geoMatch) {
        this.specCharacter = '1';
        this.opportunity = '-';
        this.optOut = '-';
        this.LSPA = '-';
      } else {
        this.specCharacter = '1';
        this.opportunity = 'Y';
        this.optOut = 'N';
        this.LSPA = 'N';
      }
      this.generateUSPString();
      setUSPString(this.uspString);
      setUSPObject(this.uspString);
      setUSPDataCookie(this.uspString, this.specCharacter);
    }
  }
}
