import { getOneCookie } from '@turnercode/cdp-utils-js';

function getDomain() {
  const psmMetaData = getOneCookie('psmMetaData');
  let cookieDomain = window.location.hostname;
  if (psmMetaData?.domain) {
    cookieDomain = psmMetaData.domain;
  }
  return cookieDomain;
}
export { getDomain };
