/* eslint-disable no-console */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import { getOneCookie, makeRequest, getCurrentTime } from '@turnercode/cdp-utils-js';

import { getUrl, getPsmVersion } from './constants';

function createBaseEvent(params = {}) {
  const { psmMetaData = getOneCookie('psmMetaData'), wmukid } = params;

  let wmukidCookie = wmukid;

  // If no wmukid param passed, try and get the ID from the cookie itself
  if (!wmukidCookie) {
    wmukidCookie = getOneCookie('WMUKID')?.id;
  }

  let baseObject = {};

  // Fields shared across all Doppler payloads
  baseObject = {
    appId: psmMetaData.appId || 'Unknown',
    wmukid: wmukidCookie || 'Unknown',
    wmhhid: getOneCookie('wmhhid'), // currently null
    wminid: getOneCookie('wminid'), // currently null
    attuid: getOneCookie('firstpartyuid'), // currently null
    timestamp: getCurrentTime(),
    brand: psmMetaData.brand || null,
    domain: psmMetaData.domain || null,
    userAgent: window.navigator.userAgent.toString(),
    platform: 'web',
    library: {
      name: 'Psm',
      version: getPsmVersion(),
    },
  };

  // Only attach ids object if there are IDs in the object
  const ids = getOneCookie('psmExternalIds') || {};

  if (Object.keys(ids).length !== 0) {
    if (ids.kruxid && ids.kruxid !== 'retry') {
      baseObject.ids = ids;
    }
  }

  return baseObject;
}

function debugLogger(message) {
  console.info(message);
}

function errorLogger(eventType, method, errorDetails, flag) {
  let wmUkId = '{}';
  try {
    wmUkId = getOneCookie('WMUKID');
    wmUkId = wmUkId.id;
  } catch (e) {
    wmUkId = 'Unknown';
  }
  const psmMetaData = getOneCookie('psmMetaData');
  const appId = psmMetaData.appId || null;
  const brand = psmMetaData.brand || null;
  const errorObject = {
    message: {
      eventType,
      method,
      WMUKID: wmUkId,
      Brand: brand,
      appId,
      Platform: 'Web',
      error: true,
      errorMessage: errorDetails.message,
      flagName: flag,
      enabled: true,
    },
  };
  const env = psmMetaData.environment || 'PROD';
  const errorUrl = getUrl(env, 'errors');
  makeRequest({ url: errorUrl, method: 'POST', payload: errorObject });
}

export { errorLogger, debugLogger, createBaseEvent };
