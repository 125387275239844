import { getOneCookie } from '@turnercode/cdp-utils-js';

import { getGeoMatch, isPrivacyEnabled } from '../helpers';
import { CCPA_NON_USP_LOCATION_STRING } from '../helpers/constants';

function getUSPString() {
  if (!isPrivacyEnabled()) {
    return;
  }
  if (!getGeoMatch()) {
    return CCPA_NON_USP_LOCATION_STRING;
  }
  const uspString = getOneCookie('usprivacy');

  // sendTelemetryEvent('getUSPString', 'privacy-getUSPString', {});

  return uspString;
}

function getUSPObject() {
  if (!isPrivacyEnabled() || !getGeoMatch()) {
    return;
  }
  const uspObject = getOneCookie('uspObject');

  // sendTelemetryEvent('getUSPObject', 'privacy-getUSPObject', {});

  return uspObject;
}

function getUSPData() {
  if (!isPrivacyEnabled() || !getGeoMatch()) {
    return;
  }
  const uspData = getOneCookie('uspData');

  // sendTelemetryEvent('getUSPData', 'privacy-getUSPData', {});

  return uspData;
}

function getUSPBoolean() {
  if (!isPrivacyEnabled() || !getGeoMatch()) {
    return;
  }
  let uspBoolean = false;
  const uspString = getOneCookie('usprivacy');
  const uspCharacters = (uspString && uspString.split('')) || [];
  const optOut = uspCharacters[2];
  if (optOut === 'Y') {
    uspBoolean = true;
  }

  // sendTelemetryEvent('getUSPBoolean', 'privacy-getUSPBoolean', {});
  return uspBoolean;
}

function getUSPInt() {
  if (!isPrivacyEnabled() || !getGeoMatch()) {
    return;
  }
  let uspInt = 0;
  const uspString = getOneCookie('usprivacy');
  const uspCharacters = (uspString && uspString.split('')) || [];
  const optOut = uspCharacters[2];
  if (optOut === 'Y') {
    uspInt = 1;
  }

  // sendTelemetryEvent('getUSPInt', 'privacy-getUSPInt', {});

  return uspInt;
}

export { getUSPString, getUSPObject, getUSPBoolean, getUSPInt, getUSPData };
