import { getOneCookie } from '@turnercode/cdp-utils-js';

function getGeoMatch() {
  const geoLocations = ['US', 'PR', 'VI', 'UM', ''];
  const psmMetaData = getOneCookie('psmMetaData');
  const userLocation = psmMetaData?.location;
  const geoMatch = userLocation && geoLocations.indexOf(userLocation.toUpperCase()) >= 0;
  return geoMatch;
}

export { getGeoMatch };
