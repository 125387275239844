import { generateUUID, getGeoLocation, makeRequest } from '@turnercode/cdp-utils-js';

class DefaultConfigService {
  constructor() {
    this._config = {
      dopplerUrl: '',
      baseEvent: () => ({}),
      isFeatureEnabled: () => Promise.resolve(true),
      version: '',
      isTelemetryEnabled: () => true
    };
  }

  config() {
    return this._config;
  }

  applyConfig(config) {
    // TODO: Perhaps make this a merge rather than a replace?
    this._config = config;
  }

}

class DefaultDopplerService {
  constructor(configService, featureFlagService) {
    this.configService = void 0;
    this.featureFlagService = void 0;
    this.sourceIp = void 0;
    this.configService = configService;
    this.featureFlagService = featureFlagService;
  }

  async sendEvent(eventName, payload) {
    if (!this.featureFlagService.telemetryEnabled()) {
      return {
        message: `Event ${eventName} not sent because the Telemetry Feature Flag is disabled.`
      };
    }

    const message = [{ ...this.configService.config().baseEvent(),
      eventId: generateUUID(),
      eventType: 'telemetry',
      eventName,
      companyName: 'WarnerMedia',
      productName: this.brand(),
      applicationName: this.brand(),
      device: {
        type: navigator.platform,
        name: null,
        model: null,
        osName: null,
        osVersion: null
      },
      userProperties: {
        wmhhid: null,
        wminid: null
      },
      context: {
        appLocation: this.location(),
        sourceIp: await this.ipAddress(),
        userAgent: navigator.userAgent.toString()
      },
      library: {
        name: 'Psm',
        version: this.configService.config().version
      },
      eventProperties: payload
    }];
    const dopplerBaseUrl = this.configService.config().dopplerUrl;

    if (!dopplerBaseUrl) {
      return Promise.reject('Misconfigured build. DOPPLER_URL not provided');
    }

    if (await this.featureFlagService.eventEnabled(eventName)) {
      return this.doSend(dopplerBaseUrl, message).then(r => {
        return r;
      });
    } else {
      return {
        message: `Event ${eventName} not sent because it is not enabled by Feature Flags`
      };
    }
  } // If this is useful outside of telemetry, we can move it to cdp-utilities


  location() {
    // TODO: Implement safeguards for isomorphic support
    return window && window.location && window.location.href;
  }

  brand() {
    const baseEvent = this.configService.config().baseEvent();
    return baseEvent.brand || null;
  }

  async ipAddress() {
    if (this.sourceIp) {
      return Promise.resolve(this.sourceIp);
    }

    const location = await getGeoLocation();

    if (location) {
      this.sourceIp = location['ip_address'];
    } else {
      this.sourceIp = 'Unknown';
    }

    return Promise.resolve(this.sourceIp);
  } // Extracted to simplify testing


  async doSend(url, message) {
    try {
      const request = {
        url,
        method: 'POST',
        payload: JSON.stringify(message)
      };
      return makeRequest(request);
    } catch (error) {
      return Promise.reject(error);
    }
  }

}

class WrappedFeatureFlagService {
  constructor(configService) {
    this.TELEMETRY_EVENT_PREFIX = 'telemetry.events.';
    this.config = void 0;
    this.config = configService && configService.config();
  }

  async eventEnabled(eventName, unknownFlagsEnabled = true) {
    return this.featureEnabled(`${this.TELEMETRY_EVENT_PREFIX}${eventName}`, unknownFlagsEnabled);
  }

  async featureEnabled(featureName, unknownFlagsEnabled = true) {
    return this.config.isFeatureEnabled(featureName, unknownFlagsEnabled).then(enabled => {
      return enabled;
    }).catch(() => {
      return unknownFlagsEnabled;
    });
  }

  telemetryEnabled() {
    return this.config.isTelemetryEnabled();
  }

  heartbeatFrequency() {
    return 5;
  }

  heartbeatInactivityTimeout() {
    return 60;
  }

}

const configService = new DefaultConfigService();
const featureFlagService = new WrappedFeatureFlagService(configService);
const dopplerService = new DefaultDopplerService(configService, featureFlagService);
const container = {
  configService,
  featureFlagService,
  dopplerService
};

class TelemetryBase {
  constructor(dopplerService, featureFlagService) {
    this.dopplerService = void 0;
    this._featureFlagService = void 0;
    this.observers = [];
    this.dopplerService = dopplerService;
    this._featureFlagService = featureFlagService;
    this.sendAppLoad();
  }

  addObserver(observer) {
    this.observers.push(observer);
  }

  featureFlagService() {
    return this._featureFlagService;
  }

  notifyObservers(payload) {
    this.observers.map(observer => observer.notified(payload));
  }

  sendAppLoad() {
    return this.sendEvent('appLoad', {});
  }

  async sendEvent(eventName, payload) {
    if (!this._featureFlagService.telemetryEnabled()) {
      return {
        message: `Event ${eventName} not sent because the Telemetry Feature Flag is disabled.`
      };
    }

    return this.dopplerService.sendEvent(eventName, payload).then(r => {
      this.notifyObservers(r);
      return r;
    });
  }

}

function initTelemetry(config) {
  container.configService.applyConfig(config);
  const telemetry = new TelemetryBase(container.dopplerService, container.featureFlagService);
  return telemetry;
}

class CallbackObserver {
  constructor(callback) {
    this.callback = void 0;
    this.callback = callback;
  }

  notified(message) {
    this.callback(message);
  }

}

export { CallbackObserver, initTelemetry };
