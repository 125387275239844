/* eslint-disable no-plusplus */
import { defaultFlagState } from './constants';

function findFlag(data, idToLookFor) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].flagId === idToLookFor) {
      return data[i];
    }
  }
}

function getFlagState(flagName) {
  let featureFlag = {};
  try {
    const featureFlagFromSessionStorage = JSON.parse(sessionStorage.getItem('psmFeatureFlags'));

    if (featureFlagFromSessionStorage) {
      featureFlag = findFlag(featureFlagFromSessionStorage, flagName) || {};
    } else {
      featureFlag = findFlag(defaultFlagState, flagName) || {};
    }
  } catch (e) {
    featureFlag = findFlag(defaultFlagState, flagName) || {};
  }
  return featureFlag.enabled || false;
}

function isPrivacyEnabled() {
  const privacyEnabled = getFlagState('getSetConsentState');
  return privacyEnabled;
}

function isTelemetryEnabled() {
  const telemetryEnabled = getFlagState('telemetry');
  return telemetryEnabled;
}

function isIdentityEnabled() {
  const identityEnabled = getFlagState('WMUKID');
  return identityEnabled;
}

export { isPrivacyEnabled, isIdentityEnabled, isTelemetryEnabled, getFlagState };
