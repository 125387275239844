import { v4 } from 'uuid';

function getCurrentTime() {
  return new Date().toISOString();
}

/**
 * helper function for http methods ie. GET, POST, etc.
 * @param request
 * @returns request
 */
function makeRequest(request) {
  return new Promise((resolve, reject) => {
    try {
      const data = extractRequestPayload(request);
      const xhr = new XMLHttpRequest();
      xhr.open(request.method, request.url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');

      if (request.headers && Object.keys(request.headers).length > 0) {
        for (const property in request.headers) {
          if (Object.prototype.hasOwnProperty.call(request.headers, property)) {
            xhr.setRequestHeader(property, request.headers[property]);
          }
        }
      }

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === 4) {
          const responseParam = {
            status: xhr.status,
            statusText: xhr.statusText,
            headers: xhr.getAllResponseHeaders(),
            data: ''
          };

          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              responseParam.data = JSON.parse(xhr.response);
              return resolve(JSON.parse(JSON.stringify(responseParam)));
            } catch (e) {
              return resolve(xhr.response);
            }
          }
        }
      });
      xhr.addEventListener('error', () => {
        return reject({
          status: xhr.status,
          statusText: `An error occurred during the transaction`
        });
      });
      xhr.send(data);
    } catch (e) {
      return reject(new Error(e.message));
    }
  });
}

function extractRequestPayload(request) {
  if (typeof request.payload === 'string') {
    return request.payload;
  } else if (request.payload instanceof String) {
    return request.payload.valueOf();
  } else {
    return JSON.stringify(request.payload);
  }
}

function isEnvNode() {
  try {
    return typeof process !== 'undefined' && typeof process.versions.node !== 'undefined';
  } catch (error) {
    return false;
  }
}
function generateUUID() {
  return v4();
}

//@ts-nocheck

function getGeoLocation() {
  return new Promise((resolve, reject) => {
    const request = {
      url: 'https://geo.ngtv.io/locate',
      method: 'GET'
    };
    makeRequest(request).then(response => resolve(response.data)).catch(error => {
      // TODO - need to revisit once logger will finalise
      // errorLogger(error);
      return reject(error);
    });
  });
}

/* eslint-disable @typescript-eslint/no-explicit-any */
class Logger {
  constructor(url, metadata) {
    this.url = void 0;
    this.metadata = void 0;
    this.level = void 0;
    this.url = url;
    this.metadata = metadata;
    this.level = window.location.search.search(/[?&]psm_debug=[1t]/) !== -1 ? 'debug' : 'error';
  }

  transport(level, scope, caller, ...args) {
    const payload = {
      message: {
        version: this.metadata.version,
        level,
        appId: this.metadata.appId,
        wmukid: this.metadata.wmukid,
        eventId: generateUUID(),
        timestamp: new Date().toISOString(),
        brand: this.metadata.brand,
        domain: this.metadata.domain,
        userAgent: window.navigator.userAgent.toString(),
        platform: 'Web',
        scope,
        caller,
        data: args
      }
    };
    makeRequest({
      url: this.url,
      method: 'POST',
      payload: JSON.stringify(payload, null, 2),
      headers: {
        type: 'application/json'
      }
    });
  }

  debug(scope, caller, ...args) {
    if (this.level !== 'debug') {
      return;
    }

    console.info('[PSM]', scope, caller, ...args);
    this.transport('debug', scope, caller, ...args);
  }

  error(scope, caller, ...args) {
    if (this.level === 'debug') {
      console.error('[PSM]', scope, caller, ...args);
    }

    this.transport('error', scope, caller, ...args);
  }

}

export { Logger as L, generateUUID as a, getCurrentTime as b, getGeoLocation as g, isEnvNode as i, makeRequest as m };
