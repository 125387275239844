import { getGeoLocation, getOneCookie, removeCookie, setCookie } from '@turnercode/cdp-utils-js';
import { initTelemetry } from '@turnercode/cdp-telemetry-core';
import { FeatureFlagClient } from '@turnercode/cdp-feature-flag-client';

import {
  createBaseEvent,
  errorLogger,
  isIdentityEnabled,
  isPrivacyEnabled,
  isTelemetryEnabled,
  sendTelemetryEvent,
} from './modules/helpers';
import { defaultFlagState, ffLocalConfig, getPsmVersion, getUrl } from './modules/helpers/constants';
import { getWMUKID, removeWMUKID, updateExternalIds } from './modules/identity';
import { PrivacyModule } from './modules/privacy';

const privacyModule = new PrivacyModule();

async function getFeatureFlags(ffClient) {
  try {
    const featureFlags = await ffClient.queryAllFeatureFlags();
    sessionStorage.setItem('psmFeatureFlags', JSON.stringify(featureFlags.results));
    if (featureFlags.anyFlagsUpdatedSinceLastQuery)
      sendTelemetryEvent('getFeatureFlags', 'featureflagging-queryAllFeatureFlags', { featureFlags });
  } catch (error) {
    sessionStorage.setItem('psmFeatureFlags', JSON.stringify(defaultFlagState));
    errorLogger('FeatureFlagging', 'queryAllFeatureFlags', error);
  }
}
/**
 *
 * @param {Object} psmMetaData - AppID, BrandName, Environment, Domain
 * @param {String} telemetryUrl - based on environment it will load
 * @param {Object} FFClient - Feature Flag client confgiuration
 */
function initializeTelemetry(psmMetaData, telemetryUrl, FFClient) {
  const telemetryModule = initTelemetry({
    baseEvent: () => createBaseEvent({ psmMetaData }),
    dopplerUrl: telemetryUrl,
    isFeatureEnabled: async (flagName, flagDefault) => {
      try {
        return await FFClient.isFeatureEnabled(flagName);
      } catch (e) {
        return flagDefault;
      }
    },
    version: getPsmVersion(),
    isTelemetryEnabled,
  });
  window.WM.CDP.telemetryModule = telemetryModule;
}
/**
 *
 * @param {String} appId - The app ID the CDP team provides
 * @param {String} brandName - Your brand name, e.g. CNN, should match the brand provided when registering in CMDB.  If you do not know what to pass here reach out to the Psm development team
 * @param {String} environment - production, development, qa, or test
 * @param {String} domain - the domain of your app , e.g. cnn.com
 * @param {String} locationOverride- Optional parameter to override the Psm geolocation service
 */
async function initPsm(appId, brandName, environment, domain, locationOverride) {
  const psmMetaData = { appId, brand: brandName, environment: environment.toUpperCase(), domain };

  const featureFlagUrl = getUrl(environment, 'featureFlag');
  const telemetryUrl = getUrl(environment, 'telemetry');

  const idsUpdated = updateExternalIds(domain);

  if (idsUpdated) {
    removeWMUKID();
    if (getOneCookie('usprivacy') === '1YYN') {
      setCookie('retryPrivacy', 'true', domain);
    }
  }

  const FFClient = new FeatureFlagClient(
    {
      configUrl: featureFlagUrl,
      configRefreshInterval: 60000,
      userTargetingProperties: {
        Platform: ['Web'],
        Brand: [brandName],
      },
    },
    ffLocalConfig,
    true
  );

  if (locationOverride) {
    psmMetaData.location = locationOverride;
  } else {
    const geoService = await getGeoLocation();
    const userLocation = geoService.country_alpha2;
    psmMetaData.location = userLocation;
  }

  setCookie('psmMetaData', psmMetaData, domain);
  removeCookie('psmFeatureFlags', domain);

  await getFeatureFlags(FFClient);
  initializeTelemetry(psmMetaData, telemetryUrl, FFClient);
  getWMUKID();
  privacyModule.initPrivacy(true);
}

// initialize WM and WM.CDP objects
window.WM = window.WM || {};
window.WM.CDP = window.WM.CDP || {};

// IAB method
window.__uspapi = privacyModule.__uspapi;

// Expose Consent
window.WM.CDP.getUSPString = function () {
  sendTelemetryEvent('getUSPString', 'privacy-getUSPString', {});
  return privacyModule.getUSPString();
};
window.WM.CDP.getUSPBoolean = function () {
  sendTelemetryEvent('getUSPBoolean', 'privacy-getUSPBoolean', {});
  return privacyModule.getUSPBoolean();
};
window.WM.CDP.getUSPInt = function () {
  sendTelemetryEvent('getUSPInt', 'privacy-getUSPInt', {});
  return privacyModule.getUSPInt();
};
window.WM.CDP.getUSPObject = function () {
  sendTelemetryEvent('getUSPObject', 'privacy-getUSPObject', {});
  return privacyModule.getUSPObject();
};
window.WM.CDP.getUSPData = function () {
  sendTelemetryEvent('getUSPData', 'privacy-getUSPData', {});
  return privacyModule.getUSPData();
};
// Set Consent
window.WM.CDP.ccpaDoNotShare = privacyModule.ccpaDoNotShare;
window.WM.CDP.ccpaShareData = privacyModule.ccpaShareData;
window.WM.CDP.setUSPData = privacyModule.setUSPData;

// Get WMUKID
window.WM.CDP.getWMUKID = getWMUKID;

// Remove WMUKID
window.WM.CDP.removeWMUKID = removeWMUKID;

// init Psm
window.WM.CDP.initPsm = initPsm;

// Feature Flag state
window.WM.CDP.isIdentityEnabled = isIdentityEnabled;
window.WM.CDP.isPrivacyEnabled = isPrivacyEnabled;
window.WM.CDP.isTelemetryEnabled = isTelemetryEnabled;

// Psm Utils
window.WM.CDP.getVersion = getPsmVersion;

export const {
  __uspapi,
  getUSPString,
  getUSPBoolean,
  getUSPInt,
  getUSPObject,
  getUSPData,
  setUSPData,
  ccpaDoNotShare,
  ccpaShareData,
} = privacyModule;

export { initPsm };
