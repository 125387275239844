/**
 * Internal function to find a frame, if it exists
 *
 * @param {string} locator - Locator string to use to find frame
 * @returns {object} - Child frame object if found, else null
 */
function findFrame(locator) {
  let cf = null;
  let w;

  for (w = window; w; w = w.parent) {
    try {
      if (w.frames && w.frames[locator]) {
        cf = w;
        break;
      }
    } catch (e) {
      throw new Error(e);
    }
    if (w === window.top) {
      break;
    }
  }
  return cf;
}
/**
 * Internal function to add a frame for messaging
 *
 * @param {string} locator - locator string to use to name new frame
 * @returns {boolean} - true if successful, false if not
 */
function addFrame(locator) {
  let iframe;

  if (!window.frames[locator]) {
    if (document.body) {
      iframe = document.createElement('iframe');
      iframe.style.cssText = 'display:none';
      iframe.name = locator;

      document.body.appendChild(iframe);
    } else {
      /* In the case where this stub is located in the head,
       * this allows us to inject the iframe more quickly than
       * relying on DOMContentLoaded or other events.
       */
      setTimeout(addFrame.bind(window, locator), 5);
    }
    return true;
  }
  return false;
}
/**
 * Internal function to set a messaging handler
 *
 * @param {function} handler - Function to use as messaging handler
 */
function setHandler(handler) {
  if (window.addEventListener) {
    window.addEventListener('message', handler, false);
  } else {
    window.attachEvent('onmessage', handler);
  }
}

export { findFrame, addFrame, setHandler };
