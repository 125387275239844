export { L as Logger, a as generateUUID, b as getCurrentTime, g as getGeoLocation, i as isEnvNode, m as makeRequest } from './logger-78d4afc0.esm.js';
import 'uuid';

function getGlobalObject() {
  if (typeof self !== 'undefined') {
    return self;
  }

  if (typeof window !== 'undefined') {
    return window;
  }

  if (typeof global !== 'undefined') {
    return global;
  }

  throw new Error('cannot find the global object');
}

function getCookies() {
  const cookies = document.cookie;
  const output = {};
  cookies.split(/\s*;\s*/).forEach(pair => {
    const pairs = pair.split(/\s*=\s*/);
    output[pairs[0]] = pairs.splice(1).join('=');
  });
  return output;
}

function getCookiesArray() {
  const cookies = document.cookie;
  const output = [];
  cookies.split(/\s*;\s*/).forEach(pair => {
    const pairs = pair.split(/\s*=\s*/);
    const cookieObject = {};
    cookieObject['key'] = pairs[0];
    cookieObject['value'] = pairs[1];
    output.push(cookieObject);
  });
  return output;
}

function getOneCookie(cookieName) {
  let fullCookieName; // Check if cookieName is already encoded

  try {
    decodeURIComponent(cookieName); // already encoded

    fullCookieName = `${cookieName}=`;
  } catch (e) {
    // needs encoding
    fullCookieName = `${encodeURIComponent(cookieName)}=`;
  }

  const cookies = document.cookie.match(`(^|;)\\s*${fullCookieName}\\s*([^;]+)`); // decode cookie value on return
  //const cookie = cookies?.pop();

  const cookie = cookies && cookies.pop();

  if (cookie) {
    try {
      return JSON.parse(decodeURIComponent(cookie));
    } catch {
      return decodeURIComponent(cookie);
    }
  }

  return null; //return cookie ? JSON.parse(decodeURIComponent(cookie)) : null;
}

function setCookie(cookieName, cookieObject, cookieDomain) {
  let cookieString = '';

  if (!cookieName) {
    return null; //TODO: once we finalise the logger need to implement below mentioned method.
    //return errorLogger(`Cookie name ${cookieName} invalid`);
  }

  if (!cookieObject) {
    return null; //TODO: once we finalise the logger need to implement below mentioned method.
    // return errorLogger(
    //   `Cookie object: ${JSON.stringify(cookieObject)} invalid.`
    // );
  }

  const expiration = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);

  if (typeof cookieObject === 'string') {
    cookieString = cookieObject;
  } else {
    cookieString = JSON.stringify(cookieObject);
  }

  if (!cookieDomain) {
    cookieDomain = getGlobalObject().location.hostname;
  } // cookie token & value need to be encoded before saving


  document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(cookieString)};expires=${expiration.toUTCString()}; domain=${cookieDomain}; samesite=Lax; path=/`;
  return cookieObject;
}

function removeCookie(cookieName, cookieDomain) {
  if (!cookieDomain) {
    cookieDomain = getGlobalObject().location.hostname;
  }

  if (cookieName) {
    document.cookie = `${encodeURIComponent(cookieName)}=; domain=${cookieDomain}; samesite=Lax; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

export { getCookies, getCookiesArray, getGlobalObject, getOneCookie, removeCookie, setCookie };
