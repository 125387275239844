import { isTelemetryEnabled } from './feature-flag-interface';
import { errorLogger } from './logger';

function sendTelemetryEvent(methodName, eventName, eventProperties) {
  // Needed because the telemetry library does not freeze the feature flag state--this prevents a discrepancy between what prism-js thinks the telemetry flag is and what telemetry thinks the telemetry flag is
  if (!isTelemetryEnabled()) {
    return;
  }

  /*
   * Needed to give the telemetryModule a chance to finish initializing given UserConsent
   * ccpaDoNotShare/ccpaShareData invokations occur immediately on the page load and can happen
   * before the telemetryModule is finished initializing!
   */
  return setTimeout(() => {
    try {
      return window.WM.CDP.telemetryModule.sendEvent(eventName, eventProperties);
    } catch (e) {
      errorLogger('Telemetry', methodName, e);
    }
  }, 2000);
}
export { sendTelemetryEvent };
